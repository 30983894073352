@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Montserrat', sans-serif !important;
}

.App {
  text-align: center;
}

.nav {
  height: 5vh;
  align-items: center;
  border-radius: 0 !important;
  font-weight: 700 !important;
}

.nav-item{
  height:100% !important;
  justify-content: center !important;
}

.ui.menu {
  margin: 0 !important;
}

.ui.menu .active.item{
  background-color: #44ad9f !important;
  color: white !important;

}

.profile-photo {
  border-radius: 50%;
  margin: 3vh;
  height: 30vh;
  width: 30vh;
}

a {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
  -webkit-tap-highlight-color: transparent; 
  outline: none !important;
}

.about-me {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5vh auto;
  max-width: 1500px;
  text-align: left;
  min-height: 75vh;
  width: 85%;
}

.about-me-details{
  margin-left: 5vw;
}

.about-me-text {
  text-align: left;
  font-size: 16px;
}

.social {
  display: flex;
  align-items: center;
  margin-top: 2vh;
}

.tech-logos{
  display: flex;
  justify-content: space-evenly;
}

.logo {
  width: auto;
  max-width:25%;
  max-height: 70px;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1500px;
  margin: 0 auto;
}

.project {
  border:2px solid black;
  border-radius: 2vh;
  box-shadow:1vmin 1vmin; 
  width: 80%;
  margin: 5vh 2vh; 
  max-width: 500px;
  min-width: 300px;
  /* height:600px; */
  max-height: 600px;
}

.experience{
  display: flex;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}

.font-inherit {
  font-family: Montserrat, sans-serif !important;
}

.connect-four-vid {
  width: 80%;
  max-width: 900px;
  margin-top: 10vh;
}

.project-desc {
  width: 80%;
  max-width: 900px;
  margin: 5vh auto;
  font-size: 16px;
  text-align: left;
}

code{
  color:white;
  background-color:#44ad9f;
  padding: 1vh;
  line-height: 3;
}

.App-header {
  background:  linear-gradient(rgba(0, 0, 0, 0.35),rgba(0, 0, 0, 0.35)), url('./images/juni.jpeg');
  background-position: center ;
  background-size: cover;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 7vmax);
  font-weight: 500;
  color: white;
  line-height: normal;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
